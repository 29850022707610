// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "PT Sans", sans-serif;
  height: 100vh;
  width: 100vw;
  background: #222;
  color: #ccc;
}

canvas {
  border: 3px solid #666666;
}

.game {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  height: 100%;
}
.game--with-layer {
  border-color: indianred;
}
.game__title {
  text-transform: lowercase;
}
.game__title span + span {
  margin-left: 10px;
}
.game__score {
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/scss/style.scss"],"names":[],"mappings":"AAMA;;EAEI,SAAA;EACA,UAAA;AALJ;;AAQA;EACI,kCAAA;EACA,aAAA;EACA,YAAA;EACA,gBAde;EAef,WAdI;AASR;;AAQA;EACI,yBAAA;AALJ;;AAQA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;AALJ;AAOI;EACI,uBAAA;AALR;AAQI;EACI,yBAAA;AANR;AAQQ;EACI,iBAAA;AANZ;AAUI;EACI,cAAA;AARR","sourcesContent":["@use 'sass:color';\n\n$background-color: #222;\n$color: #ccc;\n$darken: color.adjust($color, $lightness: -40%);\n\nhtml,\nbody {\n    margin: 0;\n    padding: 0;\n}\n\nbody {\n    font-family: 'PT Sans', sans-serif;\n    height: 100vh;\n    width: 100vw;\n    background: $background-color;\n    color: $color;\n}\n\ncanvas {\n    border: 3px solid $darken;\n}\n\n.game {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    height: 100%;\n    height: 100%;\n\n    &--with-layer {\n        border-color: indianred;\n    }\n\n    &__title {\n        text-transform: lowercase;\n\n        span + span {\n            margin-left: 10px;\n        }\n    }\n\n    &__score {\n        color: #ffffff;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
